<template>
  <div>
    <div class="is-flex is-flex-direction-column px-2">
      <div class="is-size-3 has-text-weight-bold mb-3">Claim Airdrop?</div>
      <div class="container has-text-white">
        <p>
          By clicking claim, you will receive your share of the WEAV airdrop.
          This is a one time action and cannot be undone.
        </p>
        <p>
          You are responsible for any tax or other legal obligations that may arise from this action.
          Only members of WeavrDAO (12321 DAO LLC) are eligible for this airdrop, if you are not a member please cancel
          the claim process and contact us on discord.
        </p>
        <p>
          As a member of WeavrDAO, you are responsible for ensuring that you are eligible to receive the airdrop, and
          that you are not in a jurisdiction that prohibits you from receiving it.
        </p>
      </div>
      <div class="is-flex is-flex-direction-row-reverse mt-6">
        <button @click="claim"
                class="button has-background-mint has-text-white has-text-weight-bold m-1">Claim
        </button>
        <button @click="onCancel"
                class="button has-background-red has-text-white has-text-weight-bold m-1">Cancel
        </button>
      </div>
    </div>
  </div>
</template>

<style scoped>
.is-max-width-60ch {
  max-width: 60ch;
}
</style>

<script>
import ServiceProvider from "@/services/provider";
import {ethers} from "ethers";
import {CONTRACTS} from "@/services/constants";

export default {
  name: "AirdropClaimModal",
  methods: {
    async refresh() {
      const airdrop = ServiceProvider.airdrop();
      const token = ServiceProvider.token();
      this.amount = ethers.utils.formatEther((await airdrop.viewClaimedAmount(this.walletAddress)));
      this.airdropBalance = ethers.utils.formatEther((await token.getTokenBalance(CONTRACTS.TOKEN_ADDRESS, this.airdropAddress)));
    },
    async claim() {
      if (parseFloat(this.airdropBalance) === 0) return;
      const airdrop = ServiceProvider.airdrop();
      await airdrop.claim();
      this.$toast.success("Claim successful!");
    },
    onCancel() {
      this.$router.back();
    }
  }
}
</script>