export const crowfundStates = {
  "0": {
    key: 0,
    value: "Active",
  },
  "1": {
    key: 1,
    value: "Executing",
  },
  "2": {
    key: 2,
    value: "Refunding",
  },
  "3": {
    key: 3,
    value: "Finished",
  }
};
