/* eslint-disable no-unused-vars */
/* eslint-disable class-methods-use-this */
class StorageNetwork {
  constructor() {
  }

  async addJson(file) {
  }

  async getFile(name) {

  }

  async getFiles(names, localStorage) {
  }
}

export default StorageNetwork