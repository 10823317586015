<template>
<div class="container p-3 is-size-7 ">
  <div class="title">TERMS OF USE</div>
  <div class="subtitle">TABLE OF CONTENTS</div>
  <div class="block is-size-7">
    <ol class="p-4">
    <li>APPLICABILITY	2</li>
    <li>CONDITIONS OF USE	2</li>
    <li>SERVICES	3</li>
    <li>PAYMENT	3</li>
    <li>INDEMNIFICATION	4</li>
    <li>USER CONTRIBUTIONS	4</li>
    <li>MONITORING AND ENFORCEMENT	5</li>
    <li>INDEMNIFICATION	6</li>
    <li>PROHIBITED CONDUCT	6</li>
    <li>FEES AND REFUND	7</li>
    <li>CONFIDENTIALITY	8</li>
    <li>ACCEPTANCE AND MODIFICATION OF TERMS OF USE	8</li>
    <li>INTELLECTUAL PROPERTY RIGHTS	9</li>
    <li>DISCLAIMER OF WARRANTIES AND LIABILITY	10</li>
    <li>VIOLATION OF THE TERMS OF USE	11</li>
    <li>SUSPENSION AND TERMINATION	11</li>
    <li>GOVERNING LAW	11</li>
    <li>FORCE MAJEURE	11</li>
    <li>NON TRANSFERABILITY	12</li>
    <li>GENERAL PROVISIONS	12</li>
    <li>REPORT ABUSE AND GRIEVANCE REDRESSAL	12</li>
    <li>SURVIVAL	16</li>
    <li>MISCELLANEOUS	16</li>
  </ol>
  </div>
  <div class="block">
    Welcome, and thank you for your interest in WeavrDAO (12321 DAO LLC) (“we”, “our,” “us” or “12321 DAO LLC”). These Terms of Use (“Terms”) govern your access to our website https://www.fractional.finance.com/  (the “Site”), access to or downloading our mobile application (the “App”), our related websites, databases, networks, software, including widgets and mobile applications (including tablet applications) (collectively “Platform”), and the services (each a “Service”, and collectively the “Services”) that we facilitate, through the Platform.
    <br>
    <br>Please read the Terms of Use and Privacy Policy carefully before using or registering on the Platform or accessing any material, information or Services through the Platform. Your access to or use of the Platform (even when you do not avail of any services on the Platform) or booking, purchase or availment of the Services shall signify your acceptance of the Terms of Use. These Terms of Use do not alter in any way the terms or conditions of any other written agreement you may have with the Platform for other services.
    <br>
    <br>We reserve the right to modify or terminate any portion of the Terms for any reason and at any time, and such modifications may be informed to you in writing. Your use of the Platform following any such modification constitutes your agreement to follow and be bound by the Terms so modified.
    <br>
    <br>You acknowledge that you will be bound by these Terms for availing any of the Services offered by us. If you do not agree with any part of the Terms, please do not use the Platform or avail any Services. Your access to use of the Platform and the Services will be solely at the discretion of 12321 DAO LLC.
  </div>
  <div class="block">
    <div class="subtitle">APPLICABILITY</div>
    <p>These Terms shall apply to all the users of the Platform including:</p>
    <p>
      Any supplier of goods / services, thread investors or governors (whether an individual, professional or an entity, or similar institution) on the Platform, directly or indirectly, including designated / authorised associates, employees, partners and practitioners thereof (“Partner”, “You”, “Your” or “Users”);
      <br>An individual who accesses and registers on the Platform and/or places a service request on the Platform (“End-User”, “You”, “Your” or “Users”); or
      <br>Otherwise a user or visitor of the Platform (“You” “Your” or “Users”).
    </p>
  </div>
  <div class="block">
    <div class="subtitle">CONDITIONS OF USE</div>
    <p> 
      You must be 18 years of age or older to register, use the Services, or visit or use the Platform in any manner. By registering, visiting and using the Site or accepting these Terms, you represent and warrant that you possess the legal right, capacity and ability to agree to these Terms and Conditions and use the Website in accordance with them. You also warrant that you are 18 years of age or older, and that you have the right, authority and capacity to use the Platform and the Services available through the Platform, and agree to and abide by these Terms.
      <br>All minor users must possess a written consent of their guardian to use the Services, or visit or use the Platform in any manner.
      <br>12321 DAO LLC reserves the right to refuse access to the Platform or Services to new Users or to terminate access granted to existing User(s) at any time without according any reasons for doing so.
    </p>
  </div>
  <div class="block">
    <div class="subtitle">SERVICES</div>
    <p>
      Our services, provided through our Platform are meant to provide investors with access to the real estate capital market at a lower cost while allowing property owners to tokenize property to liquidate assets. We do not manage, control or interfere with the practice of real estate financing through banks or traditional methods, each of whom is responsible for the professional real estate and financial services that they provide.
      <br>Please note that some of the content, text, data, graphics, images, information, suggestions, guidance, and other material (collectively, “Information”) that may be available on the Platform (including information provided in direct response in sessions/programmes) may be provided by individuals in the real estate profession. The provision of such Information does not create a licensed real estate relationship  between 12321 DAO LLC and you.
      <br>In no event shall 12321 DAO LLC be liable to you or anyone else for any decision made or action taken by you on the basis of Information obtained from 12321 DAO LLC, its employees, contractors, partners, sponsors, advertisers, licensors or otherwise from the Platform of 12321 DAO LLC. None of the Information represents or warrants that any particular investment strategy is appropriate or effective for you.
      <br>The Services are not intended to be a substitute for traditional investment and financial planning.
      <br>The Services may change from time to time, at the sole discretion of 12321 DAO LLC, and the Terms will apply to your use of the Platform to avail the Service, as well as to all information provided by you on the Platform at any given point in time.
    </p>
  </div>
  <div class="block">
    <div class="subtitle">INDEMNIFICATION</div>
    <p>
      You agree to indemnify, defend and hold harmless 12321 DAO LLC and Protected Entities from and against any and all losses, liabilities, claims, damages, demands, costs and expenses (including legal fees and disbursements in connection therewith and interest chargeable thereon) asserted against or incurred by the Protected Entities that arise out of, result from, or may be payable by virtue of, any breach or non-performance of any representation, warranty, covenant or agreement made or obligation to be performed by you pursuant to these Terms. Further, you agree to hold the Protected Entities harmless against any claims made by any third party due to, or arising out of, or in connection with, your use of the Platform or Services or Information, any misrepresentation with respect to the data or information provided by you, your violation of these Terms, or your violation of any rights of another, including any intellectual property rights.
    </p>
  </div>
  <div class="block">
    <div class="subtitle">USER CONTRIBUTIONS</div>
    <p>
      The Platform may contain message boards, chat rooms (i.e. Discord, Telegram etc.), personal web pages or profiles, forums, bulletin boards and other interactive features (collectively, "Interactive Services") that allow users to post, submit, publish, display or transmit to other users or other persons ("post") content or materials (collectively, "User Contributions") on or through the Platform. Discord is a communication software that allows the users to message or call one another. Telegram accounts are tied to telephone numbers and are verified by SMS. Each service (in addition to any others that may be used) have unique terms of use provisions separate from 12321 DAO LLC that users are responsible for understanding/agreeing to.
    </p>    
    <p>
      All User Contributions must comply with the 12321 DAO LLC Discord Server content standards as set out herein above in Prohibited Uses.
      <br>Any User Contributions you post to the site will be considered non-confidential and non-proprietary. By providing any User Contributions on the Platform, you grant us and our affiliates and service providers, and each of their and our respective licensees, successors and assigns the right to use, reproduce, modify, perform, display, distribute and otherwise disclose to third parties any such material.
    </p>
    <p>You represent and warrant that:</p>
    <p>
      You own or control all rights in and to the User Contributions and have the right to grant the license granted above to us and our affiliates and service providers, and each of their and our respective licensees, successors and assigns.
      <br>All of your User Contributions do and will comply with these Terms of Use.
      <br>You understand and acknowledge that you are responsible for any User Contributions you submit or contribute, and you, not 12321 DAO LLC, have full responsibility for such content, including its legality, reliability, accuracy and appropriateness.
      <br>You understand and acknowledge that we are not responsible, or liable to any third party, for the content or accuracy of any User Contributions posted by you or any other user of the Site.
    </p>
  </div> 
  <div class="block">
    <div class="subtitle">MONITORING AND ENFORCEMENT</div>
    <p>We have the right to:</p>
    <p>
      Remove or refuse to post any User Contributions for any or no reason in our sole discretion.
      <br>Take any action with respect to any User Contributions that we deem necessary or appropriate in our sole discretion, including if we believe that such User Contributions violate the Terms of Use, infringe any intellectual property right or other right of any person or entity, threaten the personal safety of users of the Site or the public or could create liability for the Company (12321 DAO LLC).
      <br>Disclose your identity or other information about you to any third party who claims that material posted by you violates their rights, including their intellectual property rights or their right to privacy.
      <br>Take appropriate legal action, including without limitation, referral to law enforcement, for any illegal or unauthorized use of the Platform.
      <br>Terminate or suspend your access to all or part of the Platform for any violation of these Terms of Use.
      <br>Without limiting the foregoing, we have the right to fully cooperate with any law enforcement authorities or court order requesting or directing us to disclose the identity or other information of anyone posting any materials on or through the Platform.
      <br>You agree to hold harmless 12321 DAO LLC from any claims resulting from any action taken by or on behalf of 12321 DAO LLC during or as a result of its investigations and from any actions taken as a consequence of investigations by either the government authorities/such parties or law enforcement authorities with respect to your User Contributions, and agree to waive any claims against 12321 DAO LLC resulting from any such actions where your User Contributions are found to be in violation of these Terms of Use.
      <br>We do not undertake to review all material before it is posted on the Platform, and cannot ensure prompt removal of objectionable material after it has been posted. Accordingly, we assume no liability for any action or inaction regarding transmissions, communications or content provided by any user or third party or for performance or non-performance of the activities described in this section.
    </p>
  </div>
  <div class="block">
    <div class="subtitle">PROHIBITED CONDUCT</div>
    <p>
      In consideration for access to the Platform, you agree not to:
    </p>
    <p>
      use the Platform for any illegal purpose, or in violation of any applicable law or regulation, including, without limitation, any local, state, provincial, territorial, national, or international law;
      <br>use the Platform in any manner that would defame, abuse, harass, threaten or otherwise violate the legal rights of others;
      <br>violate, or encourage others to violate, any right of a third party, including by infringing or misappropriating any third-party intellectual property or privacy right;
      <br>upload any files that contain software or other material protected by applicable intellectual property laws, unless you own or control the rights thereto or have received all necessary consents;
      <br>post, upload, or distribute any file or other content that is unlawful, defamatory, libellous, inaccurate, or that a reasonable person could deem to be objectionable, profane, indecent, pornographic, harassing, threatening, embarrassing, hateful, or otherwise inappropriate;
      <br>interfere with security-related features of the Platform, including by: (i) disabling or circumventing features that prevent or limit use or copying of any content; or (ii) reverse engineering or otherwise attempting to discover the source code of any portion of the Platform except to the extent that the activity is expressly permitted by applicable law;
      <br>interfere with the operation of the Platform or enjoyment of the Platform of any user, including by: (i) uploading or otherwise any disseminating virus, adware, spyware, worm, or other malicious code; (ii) making any unsolicited offer or advertisement; (iii) attempting to collect personal information about others; (iv) interfering with or disrupting any network, equipment, or server connected to or used to provide any Service; (v) violating any regulation, policy, or procedure of any network, equipment, or server; or (vi) manipulating or otherwise displaying the Service by using framing, mirroring or similar navigational technology;
      <br>attempt to gain unauthorized access to any portion of feature of the Platform or any other systems or networks connected to the Platform, to servers operated by us, or to any part of the Platform, including by way of hacking, password mining or any other illegitimate means;
      <br>Probe, scan or test the vulnerability of the Platform or any network connected thereto, nor breach the security or authentication measures on the Platform or any network connected to the Platform. You may not reverse look-up, trace or seek to trace any information on any other user, of or visitor to the Platform, or any other customer, including any account not owned by you, to its source, or exploit the Platform or information made available or offered by or through the Platform, in any way whether or not the purpose is to reveal any information, including but not limited to personal identification information, other than your own information, as provided for by the Platform;
      <br>engage in any fraudulent activity including impersonating any person or entity, claiming a false affiliation, accessing any other Service, without permission, or falsifying your age or date of birth or any other information provided or submitted to the Service;
      <br>sell, share or otherwise transfer the access to the Platform or any material shared by 12321 DAO LLC; or attempt to do any of the acts described in this section, or assist or permit any person in engaging in any of the acts described in this section.
      <br>represent 12321 DAO LLC to solicit third parties in order to sell/share same or similar services offered by 12321 DAO LLC for commercial or non-commercial purposes. You cannot represent 12321 DAO LLC in any a capacity, unless a written and express consent has been provided by 12321 DAO LLC.
    </p>
  </div>
  <div class="block">
    <div class="subtitle">CONFIDENTIALITY</div>
    <p>
      Any Information, whether written, oral or visual, disclosed to or which comes to your possession or knowledge in connection with these Terms of Service, shall be treated as confidential and not be disclosed to others, without prior written permission of  12321 DAO LLC.
      <br>You will be responsible for maintaining the confidentiality of the Account information and are fully responsible for all activities that occur under your Account. You agree to immediately notify the Company of any unauthorized use of your Account information or any other breach of security. 12321 DAO LLC  cannot and will not be liable for any loss or damage arising from your failure to comply with this provision. You may be held liable for losses incurred by 12321 DAO LLC or any other user of or visitor to the Platform due to authorized or unauthorized use of your Account as a result of your failure in keeping your Account information secure and confidential.
      <br>Any feedback shared with 12321 DAO LLC by you, shall not be confidential in the case you have provided a consent to 12321 DAO LLC. Such feedback can be used in public domain in perpetuity with your name associated with the respective feedback.
    </p>
  </div>
  <div class="block">
    <div class="subtitle">ACCEPTANCE AND MODIFICATION OF TERMS OF USE</div>
    <p>
      Use of and access to the Platform is offered to you upon the condition of acceptance of all the terms, conditions and notices contained in these Terms, along with any amendments made by 12321 DAO LLC at its sole discretion and posted on the Platform. By (i) using this Platform or any Platform Services in any way; or (ii) merely browsing the Platform, you agree that you have read, understood and agreed to be bound by these Terms available at 12321 DAO LLC’s  site.
      <br>12321 DAO LLC retains an unconditional right to modify or amend these Terms without any requirement to notify you of the same. It shall be your responsibility to check these Terms. Your continued use of the Platform following the posting of changes to these Terms on the Platform, will constitute your consent and acceptance of those changes.
    </p>
  </div>
  <div class="block">
    <div class="subtitle">INTELLECTUAL PROPERTY RIGHTS</div>
    <p>
      The Platform, its selection and arrangement, including but not limited to, all text, videos, graphics, user interfaces, images, photos, visual interfaces, sounds and music (if any), artwork and computer code (and any combinations thereof) (collectively, the “Content”) on the Platform is owned and controlled by 12321 DAO LLC and / or its licensors and the design, structure, selection, coordination, expression, look and feel and arrangement of such Content is protected by copyright, patent and trademark laws, and various other intellectual property rights.
      <br>The trademarks, logos and service marks displayed on the Platform (“Marks”) are the property of the 12321 DAO LLC. You are not permitted to use the Marks without the prior consent of 12321 DAO LLC.
      <br>These Terms of Use permit you to use the Platform for your personal, non-commercial use only. You must not reproduce, distribute, modify, create derivative works of, publicly display, publicly perform, republish, download, store or transmit any of the material on our Platform, except as follows:
      <br>Your computer may temporarily store copies of such materials in RAM incidental to your accessing and viewing those materials.
      <br>You may store files that are automatically cached by your web browser for display enhancement purposes.
      <br>You may print or download one copy of a reasonable number of pages of the Platform for your own personal, non-commercial use and not for further reproduction, publication or distribution.
      <br>If we provide desktop, mobile or other applications for download, you may download a single copy to your computer or mobile device solely for your own personal, non-commercial use, provided you agree to be bound by our end user license agreement for such applications.
      <br>If we provide social media with certain features, you may take such actions as are enabled by such features.
    </p>
    <p>You must not:</p>
    <p>
      Modify copies of any materials from this site.
      <br>Use any illustrations, photographs, video or audio sequences or any graphics separately from the accompanying text.
      <br>Delete or alter any copyright, trademark or other proprietary rights notices from copies of materials from this site.
      <br>You must not access or use for any commercial purposes any part of the Platform or any services or materials available through the Platform. If you wish to make any use of material on the Platform other than that set out in this section, please address your request to: 12321 DAO LLC. If you print, copy, modify, download or otherwise use or provide any other person with access to any part of the Platform in breach of the Terms of Use, your right to use the Platform will cease immediately and you must, at our option, return or destroy any copies of the materials you have made. No right, title or interest in or to the Platform or any content on the site is transferred to you, and all rights not expressly granted are reserved by the Platform. Any use of the Platform not expressly permitted by these Terms of Use is a breach of these Terms of Use and may violate copyright, trademark and other laws.
    </p>
  </div>
  <div class="block">
    <div class="subtitle">DISCLAIMER OF WARRANTIES AND LIABILITY</div>
    <p>
      you expressly understand and agree that, to the maximum extent permitted by applicable law:
      <br>Platform, Services and other Content are provided by 12321 DAO LLC on an “as is” basis without warranty of any kind, express, implied, statutory or otherwise, including the implied warranties of title, non-infringement, merchantability or fitness for a particular purpose. Without limiting the foregoing, the 12321 DAO LLC makes no warranty that
      <br>the Platform or the Services or the Content will meet your requirements or your use of the Platform or the Services will be uninterrupted, timely, secure or error-free;
      <br>the results that may be obtained from the use of the Platform, or Services will be effective, accurate or reliable;
      <br>the quality of the Platform or Services or Content will meet your expectations; or
      <br>any errors or defects in the Platform or Services or Content will be corrected. No advice or information, whether oral or written, obtained by you from the company or through use of the Platform Services shall create any warranty not expressly stated in these Terms.
      <br>12321 DAO LLC has endeavoured to ensure that all the information on the Platform is correct, but the Company neither warrants nor makes any representations regarding the quality, accuracy or completeness of any data, information, or Services. 12321 DAO LLC shall not be responsible for the delay or inability to use the Platform or related functionalities, the provision of or failure to provide functionalities, or for any information, software, functionalities and related graphics obtained through the Platform, or otherwise arising out of the use of the Platform, whether based on contract, tort, negligence, strict liability or otherwise. Further, the 12321 DAO LLC shall not be held responsible for non-availability of the Platform during periodic maintenance operations or any unplanned suspension of access to the Platform that may occur due to technical reasons or for any reason beyond the 12321 DAO LLC's control. You understand and agree that any material or data or Content downloaded or otherwise obtained through the Platform is done entirely at your own discretion and risk, and that you will be solely responsible for any damage to your computer systems or loss of data that results from the download of such material or data.
    </p>
  </div>
  <div class="block">
    <div class="subtitle">VIOLATION OF THE TERMS OF USE</div>
    <p>
      You agree that any violation by You of these Terms will constitute an unlawful and unfair business practice, and may cause irreparable harm to 12321 DAO LLC, for which monetary damages would be inadequate, and you consent to 12321 DAO LLC obtaining any injunctive or equitable relief that they deem necessary or appropriate in such circumstances. These remedies are in addition to any other remedies that the 12321 DAO LLC  may have at law or in equity. If 12321 DAO LLC takes any legal action against you as a result of your violation of these Terms, they will be entitled to recover from you, and you agree to pay, all reasonable attorneys’ fees and costs of such action, in addition to any other relief that may be granted.
      <br>Injunctive Relief - You agree that a breach of these Terms will cause irreparable injury to 12321 DAO LLC for which monetary damages would not be an adequate remedy and 12321 DAO LLC shall be entitled to equitable relief in addition to any remedies it may have hereunder or at law without a bond, other security, or proof of damages.
    </p>
  </div>
  <div class="block">
    <div class="subtitle">SUSPENSION AND TERMINATION</div>
    <p>
      These Terms will continue to apply until terminated by either you or 12321 DAO LLC as set forth below. If you object to these Terms or are dissatisfied with the Platform, your only recourse is to stop accessing the Platform.
      <br>12321 DAO LLC may delist you or block your future access to the Platform or suspend or terminate your Account if it believes, in its sole and absolute discretion that you have infringed, breached, violated, abused, or unethically manipulated or exploited any term of these Terms or anyway otherwise acted unethically. Notwithstanding anything in this clause, these Terms will survive indefinitely unless and until the 12321 DAO LLC chooses to terminate them.
      <br>If you or 12321 DAO LLC terminate your use of the Platform, 12321 DAO LLC may delete any Content or other materials relating to your use of the Services and 12321 DAO LLC shall have no liability to you or any third party for doing so. However, your transactions details may be preserved by 12321 DAO LLC for purposes of tax or regulatory compliance.
      <br>You shall be liable to pay for any Services that you have already ordered till the time of termination by either party whatsoever.
    </p>
  </div>
  <div class="block">
    <div class="subtitle">GOVERNING LAW</div>
    <p>
      These terms shall be governed by and constructed in accordance with the laws of Canada, without reference to conflict of laws principles and disputes arising in relation hereto shall be subject to the exclusive jurisdiction of courts, tribunals, fora, applicable authorities at _________
      <br>Any dispute between the Parties, which cannot be resolved by the Parties within a period of 30 (thirty) days from the date the dispute was raised by a Party, shall be settled by arbitration under applicable arbitration standards relevant to Canada and any amendment thereto, and the award made in pursuance thereof shall be final and binding on the Parties. The arbitration shall be referred to a sole Arbitrator, and in event of the Parties not agreeable on appointment of the same, then to an Arbitral Tribunal consisting of 3 (three) Arbitrators, 1 (one) Arbitrator to be appointed by 12321 DAO LLC, 1 (one) Arbitrator to be appointed jointly by user and the 2 (two) Arbitrators so appointed shall appoint the third Arbitrator. If the parties fail to appoint an Arbitrator within 10 (ten) days from request to do so from the other Party, or if the 2 (two) appointed Arbitrators fail to agree on the presiding Arbitrator within 10 (ten) days from the date of their appointment, the presiding arbitrator shall be appointed in terms of the arbitration statute of Canada. The seat and venue of Arbitration shall be determined by 12321 DAO LLC at the time the dispute arises.
    </p>
  </div>
  <div class="block">
    <div class="subtitle">FORCE MAJEURE</div>
    <p>
      In the event that it is impossible or impracticable or illegal for 12321 DAO LLC to proceed with or carry out the activities in furtherance of the provided services, in whole or in part, due to any act of God, acts or decrees of the government, fire, casualty, flood, earthquake, war, strike, demonstrations, lockout, epidemic, pandemic, destruction of its facilities, riot, insurrection, materials unavailability, or any other cause(s) beyond the reasonable control of 12321 DAO LLC and as a result thereof if 12321 DAO LLC has to delay, postpone, reschedule, terminate and/or cancel the Program, 12321 DAO LLC will not be liable for any losses or damages incurred by you.
    </p>
  </div>
  <div class="block">
    <div class="subtitle">GENERAL PROVISIONS</div>
    <p>
      Notice: All notices from 12321 DAO LLC will be served by email to your registered email address or by general notification on the Platform. Any notice provided to 12321 DAO LLC  pursuant to these Terms should be sent to info@weavr.org or to our Grievance Officer.
      <br>Assignment: You cannot assign or otherwise transfer these Terms, or any rights granted hereunder to any third party. 12321 DAO LLC’s rights under these Terms are freely transferable by 12321 DAO LLC to any third party without the requirement of seeking your consent.
      <br>Severability: If, for any reason, a court of competent jurisdiction finds any provision of these Terms, or any portion thereof, to be unenforceable, that provision shall be enforced to the maximum extent permissible so as to give effect to the intent of the parties as reflected by that provision, and the remainder of these Terms shall continue in full force and effect.
      <br>Waiver: Any failure by 12321 DAO LLC to enforce or exercise any provision of these Terms, or any related right, shall not constitute a waiver by the 12321 DAO LLC of that provision or right.
    </p>
  </div>
  <div class="block">
    <div class="subtitle"></div>
    <p>
      Please provide us with a detailed description of the complaint/grievance, and we will endeavour to resolve your grievances within ten days from the date of receipt of such grievance, or within such timeframe stipulated under applicable laws.
      <br>
      <br>18.  Survival
      <br>
      <br>22.1  All sections which by their nature should survive the termination of these Terms shall continue in full force and effect subsequent to and notwithstanding any termination of these Terms by Fabric or you. Termination will not limit any of 12321 DAO LLC’s other rights or remedies at law or in equity.
      <br>
      <br>19. Miscellaneous
      <br>
      <br>23.1. These Terms constitute the entire agreement between you and 12321 DAO LLC relating to your access to and use of the Service. These Terms, and any rights and licenses granted hereunder, may not be transferred or assigned by you without the prior written consent of 12321 DAO LLC, and 12321 DAO LLC’s failure to assert any right or provision under these Terms shall not constitute a waiver of such right or provision. No waiver by either party of any breach or default hereunder shall be deemed to be a waiver of any preceding or subsequent breach or default. The section headings used herein are for reference only and shall not be read to have any legal effect.
      <br>
      <br>23.2. The Service is operated by us Canada. Those who choose to access the Service from locations outside Canada do so at their own initiative and are responsible for compliance with applicable local laws. You and 12321 DAO LLC agree that the United Nations Convention on Contracts for the International Sale of Goods will not apply to the interpretation or construction of these Terms.
      <br>
      <br>Except as otherwise provided herein, these Terms are intended solely for the benefit of the parties and are not intended to confer third-party beneficiary rights upon any other person or entity.
    </p>
  </div>












</div>
</template>

<script>
export default {
  name: "TermsPage.vue"
}
</script>

<style scoped>

</style>